* {
	box-sizing: border-box;
	margin: 0;
}

:root {
	background-color: #343a40;
	--color-brand--1: #ffb545;
	--color-brand--2: #00c46a;

	--color-dark--0: #242a2e;
	--color-dark--1: #2d3439;
	--color-dark--2: #42484d;
	--color-light--1: #aaa;
	--color-light--2: #ececec;
	--color-light--3: #d6dee0;
}

body::-webkit-scrollbar {
	display: none;
	font-family: 'Ubuntu', sans-serif;
}

body,
html {
	/* background-color: #2d3436; */
	background-image: linear-gradient(315deg, #42484d 0%, #000000 74%);
	scroll-behavior: smooth;
	/* height: -webkit-fill-available; */
}
